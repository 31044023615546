<template>
  <div class="invitation-container">
    <div style="padding: 35px; padding-top: 15px">
      <transition name="tour-guide-fade">
        <div v-if="showTitles" class="py-5 text-2xl font-weight-bold">
          {{ $t('digital_invitation_is_ready') }}
        </div>
      </transition>
      <transition name="tour-guide-fade">
        <div v-if="showTitles" class="text-xl">
          {{ $t('edit_details_in_event_settings') }}
        </div>
      </transition>
    </div>

    <v-card flat>
      <transition name="tour-guide-slide">
        <div v-show="showTitles">
          <InvitationPreview :tour-guide="true"></InvitationPreview>
        </div>
      </transition>
    </v-card>
  </div>
</template>

<script>
import InvitationPreview from '@/views/EventViews/EventDashboard/InvitationPreview.vue'
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Invitation',
  components: { InvitationPreview },
  props: {
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },
  setup(props) {
    const { showTitles } = useTourGuideTitleAnimation(props)

    return {
      showTitles,
    }
  },
}
</script>

<style scoped>
.invitation-container {
  width: 100%;
  overflow: hidden;
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 30px !important;
}

.invitation-preview {
  border-top-left-radius: 50px; /* Very rounded corners */
  border-top-right-radius: 50px; /* Very rounded corners */
  overflow: hidden;
}
</style>
